<template>
  <div>
    <spinner-loader :loading="loading" />
    <offer-form
      v-if="!loading"
      :offer="offer"
      :disabled="true"
    />
  </div>
</template>
<script>
import OfferForm from '@/common/components/Offers/OfferForm.vue'
import Offers from '@/common/compositions/Offers/OffersApi'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'

export default {
  name: 'ViewOffer',
  components: { OfferForm, SpinnerLoader },
  data() {
    return {
      loading: true,
    }
  },
  setup() {
    const {
      offer, getOfferRequest,
    } = Offers()

    return {
      offer, getOfferRequest,
    }
  },
  created() {
    this.getOfferRequest(this.$route.params.id).then(res => {
      this.offer = {
        ...res.data.data,
        attachment: [res.data.data.attachment],
        image: [res.data.data.image],
      }
    }).finally(() => {
      this.loading = false
    })
  },
}
</script>
<style lang="">

</style>
